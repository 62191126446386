import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSidenavModule } from '@angular/material/sidenav';
import {MatToolbarModule } from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';

import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { MatListModule } from '@angular/material/list';
import { ErrorInterceptor } from './core/helper/error.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';


export function HttpLoanderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JwtInterceptor } from './core/helper/jwt.interceptor';
import { MatDialogModule } from '@angular/material/dialog';
import { PDFViewerModule } from '@progress/kendo-angular-pdfviewer';
import { ComponentTrackerInterceptor } from './core/helper/component-tracker.interceptor';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    FlexLayoutModule,
    BrowserModule,
    AppRoutingModule,
    MatListModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatDialogModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoanderFactory,
        deps: [HttpClient]
      }
    }),
    PDFViewerModule,
  ],
  providers: [
   {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
   { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
   { provide: HTTP_INTERCEPTORS, useClass: ComponentTrackerInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
