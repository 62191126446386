import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ComponentTrackerInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
    this.trackNavigation();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req);
  }

  private trackNavigation(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const componentName = this.getActiveRoutePath();
        if (componentName) {
          this.saveComponentVisit(componentName);
        }
      }
    });
  }

  private getActiveRoutePath(): string {
    const segments = this.router.url.split('/').filter(segment => segment);
    return segments.length > 0 ? segments[segments.length - 1] : 'Unknown';
  }
  

  private saveComponentVisit(componentName: string): void {
    const visitedComponents: {
      NameComponent: string;
      count: number;
      userName: string;
    }[] = JSON.parse(localStorage.getItem('visitedComponents') || '[]');

    const existingComponent = visitedComponents.find(
      (c) => c.NameComponent === componentName
    );

    if (existingComponent) {
      existingComponent.count += 1;
    } else {
      visitedComponents.push({
        NameComponent: componentName,
        count: 1,
        userName: 'soul',
      });
    }

    localStorage.setItem(
      'visitedComponents',
      JSON.stringify(visitedComponents)
    );
  }
}
