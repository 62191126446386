import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map, finalize } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FrontComponentCountService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.url + environment.FrontComponentCountController;
  }

  Send(frontComponentCouns: any[]) {
    const headers = { 'Ignore-Interceptor': 'true' };
    const httpOptions = {
      headers: new HttpHeaders(headers)
    };
    return this.http.post<any>(this.url, frontComponentCouns, httpOptions).pipe(
      map((response) => {
        return response;
      }),
      finalize(() => {})
    );
  }
}
