import { Component, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { InactivityComponent } from './core/widget/inactivity/inactivity.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  browserLanguage: string;
  showSpinner = false;
  title = 'Soul Clientes';

  isAuthorized$!: Observable<boolean>;

  ngOnInit(): void {
    this.onResize();
  }

  constructor(
    private router: Router,
    private inactivityComponent: InactivityComponent,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: object
    ){
      this.inactivityComponent;

      if (isPlatformBrowser(this.platformId)) {
        this.convertPxToRem();
      }
    }


  onToggleSpinner() : void {
    this.showSpinner = !this.showSpinner;
  }

  private convertPxToRem() {
    const elements = document.querySelectorAll('*');
    elements.forEach((el) => {
      const computedStyle = window.getComputedStyle(el);
      if (computedStyle.fontSize.includes('px')) {
        const pxSize = parseFloat(computedStyle.fontSize);
        const remSize = pxSize / 16;
        this.renderer.setStyle(el, 'font-size', `${remSize}rem`);
      }
    });
  }


  onSignOut() : void {
    localStorage.removeItem('token');
    this.router.navigate(['/auth/login']);
  }

  isDesktop: boolean = true;

  screenWidth: number;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
    if(this.screenWidth <= 1100)
    {
      this.isDesktop = false;
    }
    else{
      this.isDesktop = true;
    }

    setRootFontSize();
    
  }
}


function setRootFontSize() {
  const width = window.innerWidth;
  let fontSize;

  if (width >= 1440) fontSize = 16;
  else if (width >= 1024) fontSize = 15;
  else if (width >= 768) fontSize = 14;
  else if (width >= 480) fontSize = 13;
  else fontSize = 12;

  document.documentElement.style.fontSize = `${fontSize}px`;
}

setRootFontSize();
window.addEventListener('resize', setRootFontSize);